import { type LatLng } from 'leaflet'
import { cloneDeep, isEqual } from 'lodash'

import { ErrorService } from '@/shared/service/errorService'
import { FilesService } from '@/shared/service/files.service'
import { IdsService } from '@/shared/service/utils.service'

import {
  type Lang,
  makeTranslatableText,
  type TranslatableText,
} from '../../../../types/TranslatableText.type'
import { i18n } from '../../../i18n/i18n'
import { FestivalService } from '../../../shared/service/festival.service'
import { SnackbarService } from '../../../shared/snackbar/snackbar.service'
import { InternalType } from '../../../shared/types/files.type'
import { type Scene } from '../../../shared/types/scene.type'
import { type RootGetters } from '../../../store'
import { type AugmentedRequired, fromEntries } from '../../../utils'
import { createStore, type GettersObj } from '../../../utils/createStore'
import { GeoJsonParser } from '../geojson.service'
import {
  type Coords,
  DEFAULT_MAP,
  type GeoJsonImportSummary,
  makeCoords,
  makePOI,
  makeZOI,
  type MapCopy,
  type MapEntityChoicesByType,
  type MapEntityType,
  type MapObject,
  type MarkerIcon,
  type POI,
  type ZOI,
} from '../map.type'
import { MapService } from './../map.service'

export enum MapModalStates {
  DELETE = 'DELETE',
  CHANGE = 'CHANGE',
  HIDE = 'HIDE',
}

export type GettersMap = GettersObj<typeof mapGettersMap>

export class MapState {
  zoiList: Record<string, ZOI> = {}

  map: MapObject | null = null
  mapCopy: MapCopy = {
    corners: [],
    imageOpacity: 100,
    mapOverlay: null,
    markerIcons: {},
    poiList: {},
    zoiList: {},
  }
  loading = false
  satelliteView = false

  //actions bar
  addingPoi = false
  addingZoi = false
  addingLayer = false
  importGeoJson = false

  //markers
  markerIcons: Record<string, MarkerIcon> = {}
  editingMarkerId = ''
  pic = ''
  picCrop = ''

  //layer
  mapOverlay: string | null = null
  corners: Coords[] = []
  resetting = false
  showLayerModal: MapModalStates = MapModalStates.HIDE
  imageOpacity = 100

  //import
  showImportGeoJsonModal: MapModalStates = MapModalStates.HIDE
  importGeoJsonResult: GeoJsonImportSummary | null = null

  preparedMap: MapObject | null = null

  mapFocus = ''

  newIcon = ''

  latLength = 0
  lngLength = 0

  //pois
  poiList: Record<string, POI> = {}

  //deleting modal
  type: 'ZOI' | 'POI' | 'MARKER' | '' = ''
  deletingId = ''

  entityIdChoicesByType: MapEntityChoicesByType = {
    NONE: undefined,
    GUEST: undefined,
    SCENE: undefined,
  }
}

export const {
  map,
  commit: commitMap,
  dispatch: dispatchMap,
  mapGetters: mapGettersMap,
  mapState: mapStateMap,
  useGetter: useGetterMap,
  useState: useStateMap,
} = createStore({
  namespaced: true,
  moduleName: 'map',
  initState: new MapState(),
  mutations: {
    SET_MAP_COPY(state: MapState) {
      state.mapCopy.poiList = cloneDeep(state.poiList)
      state.mapCopy.zoiList = cloneDeep(state.zoiList)
      state.mapCopy.markerIcons = cloneDeep(state.markerIcons)
      state.mapCopy.corners = cloneDeep(state.corners)
      state.mapCopy.mapOverlay = state.mapOverlay
      state.mapCopy.imageOpacity = state.imageOpacity
    },

    RESET_MAP_MODIFICATIONS(state: MapState) {
      state.resetting = true
      state.poiList = cloneDeep(state.mapCopy.poiList)
      state.zoiList = cloneDeep(state.mapCopy.zoiList)
      state.markerIcons = cloneDeep(state.mapCopy.markerIcons)
      state.mapOverlay = state.mapCopy.mapOverlay
      state.corners = cloneDeep(state.mapCopy.corners)
      state.imageOpacity = state.mapCopy.imageOpacity
    },

    END_RESET(state: MapState) {
      state.resetting = false
    },

    CHANGE_MAP_VIEW(state: MapState) {
      state.satelliteView = !state.satelliteView
    },

    LOAD(state: MapState) {
      state.loading = true
    },

    UNLOAD(state: MapState) {
      state.loading = false
    },

    SET_ADDING_POI(state: MapState) {
      state.addingPoi = !state.addingPoi
      state.addingZoi = false
      state.addingLayer = false
      state.showLayerModal = MapModalStates.HIDE
    },

    SET_ADDING_ZOI(state: MapState) {
      state.addingPoi = false
      state.addingZoi = !state.addingZoi
      state.addingLayer = false
      state.showLayerModal = MapModalStates.HIDE
    },

    SET_ADDING_LAYER(state: MapState) {
      state.addingPoi = false
      state.addingZoi = false
      state.addingLayer = !state.addingLayer
      state.showLayerModal = MapModalStates.HIDE
      state.imageOpacity = 100
    },

    SET_ADDING_NOTHING(state: MapState) {
      state.addingPoi = false
      state.addingZoi = false
      state.addingLayer = false
      state.showLayerModal = MapModalStates.HIDE
    },

    SHOW_LAYER_MODAL(state: MapState) {
      state.showLayerModal = MapModalStates.DELETE
    },

    SHOW_CHANGE_LAYER_MODAL(state: MapState) {
      state.showLayerModal = MapModalStates.CHANGE
    },

    SET_REMOVE_LAYER(state: MapState) {
      state.corners = []
      state.mapOverlay = null
    },

    OPACITY_PLUS(state: MapState) {
      if (state.imageOpacity <= 80) {
        state.imageOpacity += 20
      }
    },

    OPACITY_MINUS(state: MapState) {
      if (state.imageOpacity >= 40) {
        state.imageOpacity -= 20
      }
    },

    HIDE_LAYER_MODAL(state: MapState) {
      state.showLayerModal = MapModalStates.HIDE
    },

    ADD_POI(
      state: MapState,
      payload: AugmentedRequired<Partial<POI>, 'coords'>,
    ) {
      const id = payload.oid ? payload.oid : IdsService.generateId()
      const pois = cloneDeep(state.poiList)
      pois[id] = makePOI({
        ...payload,
        oid: id,
      })
      state.poiList = { ...pois }
    },

    SET_POI_COORDS(state: MapState, payload: { id: string; coords: Coords }) {
      if (state.poiList[payload.id]) {
        state.poiList[payload.id].coords = payload.coords
      } else {
        state.poiList[payload.id] = makePOI({
          coords: payload.coords,
          labels: makeTranslatableText(payload.id),
        })
      }
    },

    SET_POI_LAT(state: MapState, payload: { id: string; val: string }) {
      const tab = cloneDeep(state.poiList)
      tab[payload.id].coords.lat = Number(payload.val)
      state.poiList = { ...tab }
    },

    SET_POI_LNG(state: MapState, payload: { id: string; val: string }) {
      const tab = cloneDeep(state.poiList)
      tab[payload.id].coords.lng = Number(payload.val)
      state.poiList = { ...tab }
    },

    SET_POI_ENTITY(
      state: MapState,
      payload: { poiId: string; entityType: MapEntityType; entityId: string },
    ) {
      state.poiList[payload.poiId].entityId = payload.entityId
      state.poiList[payload.poiId].entityType = payload.entityType
    },

    SET_POI_LABEL(
      state: MapState,
      payload: { id: string; label: string; lang: Lang },
    ) {
      state.poiList[payload.id].labels[payload.lang] = payload.label
    },

    SET_POI_PIC(
      state: MapState,
      payload: {
        id: string
        value: {
          file: string
          oid: string | null
        }
      },
    ) {
      const tab = cloneDeep(state.poiList)
      tab[payload.id].pic = payload.value.file
      tab[payload.id].markerIconId = payload.value.oid
      state.poiList = { ...tab }
    },

    SET_DELETE_POI(state: MapState, id: string) {
      state.deletingId = id
      state.type = 'POI'
    },

    SET_DELETE_MARKER(state: MapState, id: string) {
      state.deletingId = id
      state.type = 'MARKER'
    },

    SET_EDITING_MARKER_ID(state: MapState, id: string) {
      state.editingMarkerId = id
    },

    SET_ADDING_MARKER(state: MapState) {
      state.editingMarkerId = ''
    },

    SET_PIC_CROP(state: MapState, crop: string) {
      state.picCrop = crop
    },

    //ZOI
    ADD_ZOI(
      state: MapState,
      payload: AugmentedRequired<Partial<ZOI>, 'zoneCorners'>,
    ) {
      const id = payload.oid ? payload.oid : IdsService.generateId()
      const zois = cloneDeep(state.zoiList)
      zois[id] = makeZOI({
        ...payload,
        oid: id,
      })
      state.zoiList = { ...zois }
    },

    SET_DELETE_ZOI(state: MapState, id: string) {
      state.deletingId = id
      state.type = 'ZOI'
    },

    SET_ZOI_LABEL(
      state: MapState,
      payload: { id: string; label: string; lang: Lang },
    ) {
      state.zoiList[payload.id].labels[payload.lang] = payload.label
    },

    SET_ZOI_ENTITY(
      state: MapState,
      payload: { zoiId: string; entityType: MapEntityType; entityId: string },
    ) {
      state.zoiList[payload.zoiId].entityId = payload.entityId
      state.zoiList[payload.zoiId].entityType = payload.entityType
    },

    SET_ZOI_COLOR(state: MapState, payload: { id: string; color: string }) {
      const zois = cloneDeep(state.zoiList)
      zois[payload.id].color = payload.color
      state.zoiList = { ...zois }
    },

    ZOI_EDITED(state: MapState, payload: { id: string; latLngs: LatLng[] }) {
      state.zoiList[payload.id].zoneCorners = payload.latLngs.map(
        (latLng: LatLng) => makeCoords(latLng.lat, latLng.lng),
      )
    },

    //Other
    SET_MAP_FOCUS(state: MapState, id: string) {
      state.mapFocus = id
    },

    CANCEL_DELETING(state: MapState) {
      state.type = ''
      state.deletingId = ''
    },

    //layer

    EDIT_LAYER(state: MapState, layer: { getCorners: () => LatLng[] }) {
      if (layer.getCorners()) {
        state.corners = cloneDeep(
          layer
            .getCorners()
            .map((corner: LatLng) => makeCoords(corner.lat, corner.lng)),
        )
      }
    },

    RESET_CORNERS(state: MapState) {
      state.corners = []
    },

    SET_MAP_OVERLAY(state: MapState, mapOverlay: string) {
      state.mapOverlay = mapOverlay
    },

    SET_ENTITY_ID_CHOICES(state: MapState, choices: MapEntityChoicesByType) {
      state.entityIdChoicesByType = choices
    },

    // import
    SET_IMPORT_GEOJSON(state: MapState, result: GeoJsonImportSummary | null) {
      state.importGeoJsonResult = result
    },

    SHOW_IMPORT_GEOJSON_MODAL(state: MapState) {
      state.showImportGeoJsonModal = MapModalStates.CHANGE
    },

    HIDE_IMPORT_GEOJSON_MODAL(state: MapState) {
      state.showImportGeoJsonModal = MapModalStates.HIDE
    },
  },
  actions: {
    async getMap(
      { state },
      {
        updateOnlyPreparedMap = false,
        id,
      }: {
        id: string
        updateOnlyPreparedMap?: boolean
      },
    ) {
      try {
        const [map, festival] = await Promise.all([
          MapService.getMapById(id),
          FestivalService.getFestival(),
        ])
        state.preparedMap = map
        state.markerIcons = festival.markerIcons.reduce<
          Record<string, MarkerIcon>
        >((acc, marker) => {
          if (!marker.oid) return acc
          acc[marker.oid] = marker
          return acc
        }, {})

        if (!updateOnlyPreparedMap) {
          state.map = map
          state.poiList = (map.poiList || []).reduce(
            (acc: Record<string, POI>, poi: POI) => {
              acc[poi.oid] = poi
              return acc
            },
            {},
          )
          state.zoiList = map.zoiList.reduce(
            (acc: Record<string, ZOI>, zoi: ZOI) => {
              acc[zoi.oid] = zoi
              return acc
            },
            {},
          )
          state.mapOverlay = map.mapOverlay ?? null
          state.corners = map.corners
          state.imageOpacity = map.imageOpacity
        }
      } catch (e) {
        ErrorService.handleError(e)
      }
    },

    async getMapEntityChoices({ state }) {
      try {
        const [sceneList, guestList] = await Promise.all([
          MapService.getSceneList(),
          MapService.getGuestList(),
        ])

        state.entityIdChoicesByType = {
          NONE: undefined,
          SCENE: sceneList,
          GUEST: guestList as { _id: string; names: TranslatableText }[],
        }
      } catch (e) {
        ErrorService.handleError(e)
      }
    },

    async upsertMarker({ commit, state }, marker: MarkerIcon) {
      try {
        commit('LOAD')
        state.editingMarkerId = marker.oid ?? ''
        const image = await FilesService.uploadImage(
          FilesService.getImgFromCrop(state.picCrop, InternalType.MAP_MARKER),
        )
        if (!marker.oid) return
        state.markerIcons = {
          ...state.markerIcons,
          [marker.oid]: {
            ...marker,
            file: image.filename,
          },
        }
        state.poiList = fromEntries(
          Object.values(state.poiList).map((poi) => {
            if (poi.markerIconId === marker.oid) {
              return [
                poi.oid,
                {
                  ...poi,
                  pic: image.filename,
                },
              ] as const
            }
            return [poi.oid, poi] as const
          }),
        )
        state.editingMarkerId = ''
        await FestivalService.updateMarkerIcons(
          Object.values(state.markerIcons),
        )
      } catch (e) {
        ErrorService.handleError(e)
      } finally {
        commit('UNLOAD')
      }
    },

    async delete({ commit, state }) {
      try {
        commit('LOAD')

        const id = state.deletingId
        if (state.type === 'POI' && state.poiList[id]) {
          const pois = cloneDeep(state.poiList)
          delete pois[id]
          state.poiList = pois
        }
        if (state.type === 'ZOI' && state.zoiList[id]) {
          const zois = cloneDeep(state.zoiList)
          delete zois[id]
          state.zoiList = zois
        }
        if (state.type === 'MARKER') {
          const icons = cloneDeep(state.markerIcons)
          delete icons[id]
          state.markerIcons = icons
          state.poiList = fromEntries(
            Object.values(state.poiList).map((poi) => {
              if (poi.markerIconId === id) {
                return [
                  poi.oid,
                  {
                    ...poi,
                    pic: 'DEFAULT',
                    markerIconId: null,
                  },
                ] as const
              }
              return [poi.oid, poi] as const
            }),
          )
          await FestivalService.updateMarkerIcons(
            Object.values(state.markerIcons),
          )
        }
        state.deletingId = ''
      } catch (e) {
        ErrorService.handleError(e)
      } finally {
        commit('UNLOAD')
      }
    },

    async save({ commit, getters, dispatch }) {
      commit('LOAD')
      const preparedMap = getters.getPreparedMap
      try {
        await MapService.saveMap(preparedMap)
        commit('SET_MAP_COPY')
        SnackbarService.info(i18n.tc('MAP.SNACKBAR_MSG'))
      } catch (e) {
        ErrorService.handleError(e)
      } finally {
        await dispatch('getMap', {
          id: preparedMap?._id,
          updateOnlyPreparedMap: true,
        })
        commit('UNLOAD')
      }
    },

    layerButtonPressed({ commit, state }) {
      if (!state.addingLayer && state.mapOverlay) {
        commit('SHOW_CHANGE_LAYER_MODAL')
      } else {
        commit('SET_ADDING_LAYER')
      }
    },

    async importGeoJsonFile(
      { commit, state },
      payload: { file: Blob; overwrite: boolean },
    ) {
      commit('LOAD')
      try {
        const geojson = await new Promise<string>((resolve, reject) => {
          const reader = new FileReader()
          reader.readAsText(payload.file)
          reader.onload = (): void => {
            resolve(reader.result as string)
          }
          reader.onerror = (error): void => {
            console.error(error)
            reject(
              error instanceof Error ? error : new Error('Error reading file'),
            )
          }
        })
        if (state.map?._id == null) throw new Error('Unknown map')
        const data = GeoJsonParser.parse(geojson)
        if (payload.overwrite) {
          state.poiList = {}
          state.zoiList = {}
        }
        data.pois.forEach((poi) => {
          commit(
            'ADD_POI',
            replaceMarkerImage(poi, Object.values(state.markerIcons)),
          )
        })
        data.zois.forEach((zoi) => {
          commit('ADD_ZOI', zoi)
        })
        commit('SET_IMPORT_GEOJSON', {
          pois: data.pois.length,
          zois: data.zois.length,
          errors: data.errors,
        })
      } catch (e) {
        ErrorService.handleError(e)
      } finally {
        commit('UNLOAD')
      }
    },
  },
  getters: {
    getZois: (state) => {
      return Object.values(state.zoiList)
    },

    getPois: (state) => {
      return Object.values(state.poiList)
    },

    getPoiById: (state) => {
      return (id: string): POI => {
        return state.poiList[id]
      }
    },

    getZoiById: (state) => {
      return (id: string): ZOI | undefined => {
        return state.zoiList[id]
      }
    },

    getMarkers: (state): MarkerIcon[] => {
      return [
        {
          label: 'Default',
          file: 'DEFAULT',
          oid: null,
        },
        ...Object.values(state.markerIcons),
      ]
    },

    getEditingMarker: (state): MarkerIcon => {
      return state.editingMarkerId
        ? state.markerIcons[state.editingMarkerId]
        : {
            label: '',
            file: '',
            oid: IdsService.generateId(),
          }
    },

    getPreparedMap: (state): MapObject => {
      const preparedMap = state.preparedMap ?? {
        ...DEFAULT_MAP,
      }
      preparedMap.poiList = Object.values(state.poiList)
      preparedMap.zoiList = Object.values(state.zoiList)
      preparedMap.mapOverlay = state.mapOverlay
      preparedMap.corners = state.corners
      preparedMap.imageOpacity = state.imageOpacity

      return preparedMap
    },

    getMapEdited: (state) => {
      const map: MapCopy = {
        mapOverlay: state.mapOverlay,
        corners: state.corners,
        zoiList: state.zoiList,
        poiList: state.poiList,
        markerIcons: state.markerIcons,
        imageOpacity: state.imageOpacity,
      }
      const isSame = isEqual(map, state.mapCopy)
      return !isSame
    },

    getScenes: (_state, _getters, _rootState, rootGetters): Scene[] => {
      return [...(rootGetters as RootGetters)['scenes/getScenes']]
    },

    getEntityIdChoicesByType: (state): MapEntityChoicesByType => {
      return state.entityIdChoicesByType
    },
  },
})

function replaceMarkerImage(poi: POI, markers: MarkerIcon[]): POI {
  const marker = markers.find(
    (marker) =>
      marker.label.toLocaleLowerCase() === poi.pic.toLocaleLowerCase(),
  )
  if (marker) {
    poi.markerIconId = marker.oid
    poi.pic = marker.file
  } else {
    poi.markerIconId = null
    poi.pic = 'DEFAULT'
  }
  return poi
}
