import cloneDeep from 'lodash/cloneDeep'
import isEqual from 'lodash/isEqual'
import { type TranslatableText } from 'types/TranslatableText.type'
import { type DataOptions } from 'vuetify'

import { i18n } from '@/i18n/i18n'
import router from '@/router'
import { PAGE_MODE, type PageMode } from '@/shared/constants'
import { ErrorService } from '@/shared/service/errorService'
import {
  FilesService,
  type ImageTransformations,
} from '@/shared/service/files.service'
import { SnackbarService } from '@/shared/snackbar/snackbar.service'
import { InternalType } from '@/shared/types/files.type'
import { type MusicGroup } from '@/shared/types/musicGroup/musicGroup.type'
import { type MusicGroupEventFormInput } from '@/shared/types/musicGroup/musicGroupEvent.type'
import { ProgramEventNotifSendTime } from '@/shared/types/progEvent.type'
import { isNotNullish, keys, mapByEntries, type Optional } from '@/utils'

import { type SocialLinks } from '../../../shared/service/festival.service'
import { type GuestDto } from '../../../shared/types/musicGroup/guestDto.type'
import store from '../../../store'
import { createStore, type GettersObj } from '../../../utils/createStore'
import { ProgEventService } from '../../progEvent/progEvent.service'
import { GuestsService } from '../guests.service'
import { commitGuestGenres, dispatchGuestGenres } from './guestgenres.store'

export const defaultInitGuestEvent: Partial<MusicGroupEventFormInput> = {
  scene: undefined,
  day: undefined,
  eventTicketLink: undefined,
  startTime: '12:00',
  endTime: '13:00',
  date: undefined,
  isVisible: false,
  program: undefined,
  enableAttending: false,
  enableRating: false,
  programEventNotifSendTime: ProgramEventNotifSendTime.NEVER,
  notifPlanned: false,
  ticketOpenInWebview: true,
}

export type GettersGuests = GettersObj<typeof mapGettersGuests>

export class GuestsState {
  mode: PageMode = PAGE_MODE.VIEW
  guests: Record<string, MusicGroup> = {}
  nbGuests = 0
  countByType = {}
  deleteGuestId: string | null = null
  togglingGuest: { guestId: string; names: TranslatableText } | null = null
  showHideMode: 'SHOW' | 'HIDE' = 'HIDE'
  selectedGuestRows = [] as MusicGroup[]
  loading = false
  search = ''
  selectedGuestTypeId: string | undefined
  options: Optional<
    DataOptions,
    'groupBy' | 'groupDesc' | 'multiSort' | 'mustSort'
  > = {
    page: 1,
    itemsPerPage: 10,
    sortDesc: [true],
    sortBy: ['names.fr'],
  }

  //Guest form
  editingGuest: MusicGroup | GuestDto | undefined = undefined
  links = {} as Record<
    string,
    {
      value: SocialLinks
      url: string
      text: string
    }
  >
  image: string | null = null
  guestsLinks: string[] = []

  //for Events
  guestsIdsNames: { names?: TranslatableText }[] = []

  // location
  autocompleteLocations: string[][] = []
  //
  transformations: ImageTransformations | null = null
  importBatchId = ''
  importFeedback?: {
    successes: { key: string; content: Record<string, string> }[]
    errors: { key: string; content: Record<string, string> }[]
    toProcess: { key: string; content: Record<string, string> }[]
  }

  // Guest events
  guestEventEditingOldValue: MusicGroupEventFormInput | null = null
  currentGuestEvent: Partial<MusicGroupEventFormInput> | null = null
  guestEvents: MusicGroupEventFormInput[] = []
  deletingGuestEvents: MusicGroupEventFormInput[] = []
  deletingGuestMarkers: { _id: string; name: string }[] = []
  initGuestEvent = { ...defaultInitGuestEvent }
}

export const {
  guests,
  commit: commitGuests,
  dispatch: dispatchGuests,
  mapGetters: mapGettersGuests,
  mapState: mapStateGuests,
  useState: useStateGuests,
  useGetter: useGetterGuests,
} = createStore({
  moduleName: 'guests',
  initState: new GuestsState(),
  mutations: {
    LOAD(state) {
      state.loading = true
    },

    UNLOAD(state) {
      state.loading = false
    },

    SET_GUESTS(
      state,
      {
        musicGroups,
        total,
        countByType,
      }: {
        musicGroups: MusicGroup[]
        total: number
        countByType: Record<string, number>
      },
    ) {
      state.guests = musicGroups.reduce(
        (acc: Record<string, MusicGroup>, guest) => {
          if (!guest._id) return acc
          acc[guest._id] = guest
          return acc
        },
        {},
      )
      state.nbGuests = total
      state.countByType = countByType
    },

    SET_IMAGE_TRANSFORMATIONS(state, transformations: ImageTransformations) {
      state.transformations = transformations
    },

    SET_VIEW(state) {
      state.mode = PAGE_MODE.VIEW
      state.deleteGuestId = null
      state.selectedGuestRows = []
      state.editingGuest = undefined
      state.image = null
      state.loading = false
      state.search = ''
      state.links = {}
      commitGuestGenres('SET_GENRES_SELECTION', [])
      state.guestEvents = []
    },

    SET_ADDING(state) {
      state.mode = PAGE_MODE.ADD
      state.loading = false
      state.guestEvents = []
      state.currentGuestEvent = null
      state.initGuestEvent = { ...defaultInitGuestEvent }
    },

    SET_EDITING(state, guest: MusicGroup) {
      state.mode = PAGE_MODE.EDIT
      state.editingGuest = { ...guest }
      state.image = guest.image ?? null
      state.loading = false
      state.initGuestEvent = { ...defaultInitGuestEvent }
      commitGuestGenres(
        'SET_GENRES_SELECTION',
        guest.genres
          ?.map((id) => store.state.guestGenres.genres[id])
          .filter(isNotNullish) ?? [],
      )
    },

    SET_NOT_DELETING(state) {
      state.mode = PAGE_MODE.VIEW
      state.deleteGuestId = null
    },

    SET_NOT_TOGGLING_VISIBILITY(state) {
      state.mode = PAGE_MODE.VIEW
      state.togglingGuest = null
    },

    SET_DELETING_MULTIPLE_GUESTS(state) {
      state.mode = PAGE_MODE.DELETE_MULTIPLE
    },

    SET_EDIT_MULTIPLE_GUESTS(state) {
      state.mode = PAGE_MODE.EDIT_MULTIPLE
    },

    RESET_MULTI_SELECTION(state) {
      state.selectedGuestRows = []
    },

    SET_SEARCH(state, search: string) {
      state.search = search
      state.options.page = 1
    },

    SET_SELECTED_GUEST_TYPE_ID(state, selectedGuestTypeId: string) {
      state.selectedGuestTypeId = selectedGuestTypeId
      state.options.page = 1
    },

    SET_SELECTED_GUEST_ROWS(state, guests: MusicGroup[]) {
      state.selectedGuestRows = [...guests]
    },

    SET_IMAGE(state, image: string) {
      state.image = image
      state.loading = false
    },

    SET_DELETE_LINK(state, linkValue: string) {
      delete state.links[linkValue]
      state.links = { ...state.links }
    },

    SET_LINK_URL(state, payload: { link: { value: string }; val: string }) {
      state.links[payload.link.value].url = payload.val
    },

    LOAD_LINKS(state) {
      const tmpLinks: { [key in SocialLinks]?: string } =
        state.mode !== PAGE_MODE.EDIT
          ? state.guestsLinks.reduce<Record<string, string>>((acc, link) => {
              acc[link] = ''
              return acc
            }, {})
          : keys(state.editingGuest?.links ?? {})
              .filter((key) => !!state.editingGuest?.links[key])
              .reduce<{ [key in SocialLinks]?: string }>((acc, key) => {
                if (!key) return acc
                acc[key] = state.editingGuest!.links[key]
                return acc
              }, {})
      state.links = mapByEntries(
        tmpLinks,
        ([key, value]) =>
          [
            key,
            {
              value: key,
              url: value!,
              text: i18n.tc(`GUESTS.ADDING.LINKS_LIST.${key.toUpperCase()}`),
            },
          ] as const,
      )
    },

    ADD_LINK(state, link: GuestsState['links'][string]) {
      state.links = { ...state.links, [link.value]: link }
    },

    SET_OPTIONS(state, options: GuestsState['options']) {
      state.options = options
    },

    RESET_PAGINATION(state) {
      state.options.page = 1
      state.options.itemsPerPage = 10
    },

    SET_IMPORT_FEEDBACK(state, importResult: GuestsState['importFeedback']) {
      state.importFeedback = importResult
    },

    SET_IMPORT_BATCH_ID(state, importBatchId: string) {
      state.importBatchId = importBatchId
    },

    // Guest Events

    ADD_GUEST_EVENT(state) {
      state.guestEventEditingOldValue = null
      state.currentGuestEvent = cloneDeep(state.initGuestEvent)
    },

    EDIT_GUEST_EVENT(state, guestEvent: MusicGroupEventFormInput) {
      state.guestEventEditingOldValue = cloneDeep(guestEvent)
      state.currentGuestEvent = cloneDeep(guestEvent)
    },

    UPDATE_GUEST_EVENT(state, guestEvent: MusicGroupEventFormInput) {
      state.currentGuestEvent = cloneDeep(guestEvent)
    },

    CANCEL_GUEST_EVENT(state) {
      state.guestEventEditingOldValue = null
      state.currentGuestEvent = null
    },

    SAVE_GUEST_EVENT(state) {
      if (state.guestEventEditingOldValue != null) {
        state.guestEvents = state.guestEvents.filter(
          (e) => !isEqual(e, state.guestEventEditingOldValue),
        )
      }
      if (state.currentGuestEvent) {
        state.guestEvents = [
          ...state.guestEvents,
          cloneDeep(state.currentGuestEvent) as MusicGroupEventFormInput,
        ]
      }
      state.initGuestEvent = {
        ...cloneDeep(state.currentGuestEvent),
        date: undefined,
        eventTicketLink: null,
        startTime: '12:00',
        endTime: '13:00',
      }
      state.currentGuestEvent = null
      state.guestEventEditingOldValue = null
    },

    DELETE_GUEST_EVENT(state, guestEvent: MusicGroupEventFormInput) {
      state.currentGuestEvent = null
      state.guestEvents = state.guestEvents.filter(
        (e) => !isEqual(e, guestEvent),
      )
    },

    SET_GUEST_EVENTS(state, events) {
      state.deletingGuestEvents = [...events]
    },

    SET_GUEST_MARKERS(state, markers) {
      state.deletingGuestMarkers = [...markers]
    },
  },
  actions: {
    async list({ commit }) {
      commit('LOAD')
      const guests = await GuestsService.getAll()
      commit('SET_GUESTS', guests)
      commit('UNLOAD')
    },

    async getGuestsLinks({ commit, state }) {
      try {
        commit('SET_ADDING')
        commit('LOAD')
        const guests = await GuestsService.getGuestsLinks()
        state.guestsLinks = guests
      } catch (e) {
        ErrorService.handleError(e)
      } finally {
        commit('UNLOAD')
      }
    },

    async getGuestsLight({ commit, state }) {
      try {
        commit('LOAD')
        const guests = await GuestsService.getGuestsLight()
        state.guestsIdsNames = guests
      } catch (e) {
        ErrorService.handleError(e)
      } finally {
        commit('UNLOAD')
      }
    },

    async getPaginatedGuests({ commit, state }) {
      try {
        commit('LOAD')
        const offset = (state.options.page - 1) * state.options.itemsPerPage
        const data = await GuestsService.getPaginatedGuests(
          state.search,
          state.options,
          offset,
          state.selectedGuestTypeId,
        )
        commit('SET_GUESTS', data)
        commit('RESET_MULTI_SELECTION')
      } catch (e) {
        ErrorService.handleError(e)
      } finally {
        commit('UNLOAD')
      }
    },

    async delete({ commit, state, dispatch }) {
      if (!state.deleteGuestId) return
      commit('LOAD')
      try {
        await GuestsService.deleteGuest(state.deleteGuestId)
        await dispatch('getPaginatedGuests')
        SnackbarService.info(
          i18n.tc('GUESTS.SNACK_BAR.DELETE', undefined, {
            string: state.guests[state.deleteGuestId].names.fr,
          }),
        )
      } catch (e) {
        ErrorService.handleError(e)
      } finally {
        commit('SET_VIEW')
      }
    },

    async multipleDelete({ commit, getters, dispatch }) {
      commit('LOAD')
      try {
        const guestsIds = getters.getSelectedGuestIds
        await GuestsService.deleteMultipleGuests(guestsIds)
        await dispatch('getPaginatedGuests')
        SnackbarService.info(
          i18n.tc('GUESTS.SNACK_BAR.MULTIPLE_DELETE', undefined, {
            number: guestsIds.length,
          }),
        )
      } catch (e) {
        ErrorService.handleError(e)
      } finally {
        commit('SET_VIEW')
      }
    },

    async toggleVisibility(
      { commit, dispatch, state },
      { eventsIds }: { eventsIds: string[] },
    ) {
      commit('LOAD')
      try {
        if (!state.togglingGuest) return
        await GuestsService.hideShowBatch(
          [state.togglingGuest.guestId],
          state.showHideMode === 'SHOW',
        )
        await ProgEventService.hideShowBatch(
          eventsIds,
          state.showHideMode === 'SHOW',
        )
        await dispatch('getPaginatedGuests')
        state.showHideMode
          ? SnackbarService.info(
              i18n.tc('GUESTS.SNACK_BAR.SHOW', undefined, {
                string: state.togglingGuest.names.fr,
              }),
            )
          : SnackbarService.info(
              i18n.tc('GUESTS.SNACK_BAR.HIDE', undefined, {
                string: state.togglingGuest.names.fr,
              }),
            )
      } catch (e) {
        ErrorService.handleError(e)
      } finally {
        commit('SET_VIEW')
      }
    },

    async setVisibilityBatch(
      { getters, commit, dispatch, state },
      { eventsIds }: { eventsIds: string[] },
    ) {
      commit('LOAD')
      try {
        const nb = getters.getSelectedGuestIds.length
        await GuestsService.hideShowBatch(
          getters.getSelectedGuestIds,
          state.showHideMode === 'SHOW',
        )
        await ProgEventService.hideShowBatch(
          eventsIds,
          state.showHideMode === 'SHOW',
        )
        await dispatch('getPaginatedGuests')
        state.showHideMode
          ? SnackbarService.info(
              i18n.tc('GUESTS.SNACK_BAR.BATCH_SHOW', undefined, { number: nb }),
            )
          : SnackbarService.info(
              i18n.tc('GUESTS.SNACK_BAR.BATCH_HIDE', undefined, { number: nb }),
            )
      } catch (e) {
        ErrorService.handleError(e)
      } finally {
        commit('SET_VIEW')
      }
    },

    async uploadImage({ commit }, file) {
      commit('LOAD')
      try {
        const filename = file
          ? (await FilesService.uploadImageFile(file, InternalType.PICTURE))
              .filename
          : null
        commit('SET_IMAGE', filename)
      } catch (e) {
        ErrorService.handleError(e)
      }
    },

    async addGuest({ state, commit, dispatch, rootState }, guest: MusicGroup) {
      commit('LOAD')
      try {
        const eventsCount = state.guestEvents ? state.guestEvents.length : 0
        const guestToSend = cloneDeep(guest)
        guestToSend.image = state.image ?? undefined
        guestToSend.links = {}
        Object.values(state.links).forEach((link) => {
          guestToSend.links[link.value] = link.url
        })
        guestToSend.genres = rootState.guestGenres.selectedGenres.map(
          (genre) => genre._id,
        )

        let message
        if (state.mode === PAGE_MODE.EDIT) {
          await GuestsService.updateGuest(
            guestToSend,
            cloneDeep(state.guestEvents),
          )
          message = i18n.tc('GUESTS.SNACK_BAR.EDIT', undefined, {
            string: guest.names.fr,
          })
        } else {
          await GuestsService.addGuest(
            guestToSend,
            cloneDeep(state.guestEvents),
          )
          message = i18n.tc('GUESTS.SNACK_BAR.ADD', undefined, {
            string: guest.names.fr,
          })
        }

        if (state.transformations) {
          await FilesService.setImageTransform(
            state.transformations.imageId,
            state.transformations,
          )
        }

        await dispatch('getPaginatedGuests')
        await router.push('/guests')
        SnackbarService.info(message)
        setTimeout(() => {
          eventsCount > 0
            ? SnackbarService.info(
                i18n.tc('GUESTS.SNACK_BAR.EVENTS', undefined, {
                  count: eventsCount,
                }),
              )
            : ''
        }, 3200)
      } catch (e) {
        ErrorService.handleError(e)
      } finally {
        commit('UNLOAD')
      }
    },

    async loadGuestsLocations({ commit, state }) {
      try {
        commit('LOAD')
        const locations = await GuestsService.getGuestsLocations()

        state.autocompleteLocations = locations
      } catch (e) {
        ErrorService.handleError(e)
      } finally {
        commit('UNLOAD')
      }
    },

    async loadGuest({ commit }, guestId: string) {
      commit('LOAD')
      try {
        await dispatchGuestGenres('getGenres')
        const guest = await GuestsService.getGuestById(guestId)
        if (guest.image) {
          const transformations = await FilesService.getImageTransform(
            guest.image,
          )
          commit('SET_IMAGE_TRANSFORMATIONS', transformations)
        }
        commit('SET_EDITING', guest)
        commit('LOAD_LINKS')
      } catch (e) {
        ErrorService.handleError(e)
      } finally {
        commit('UNLOAD')
      }
    },

    async saveFile({ commit, dispatch }, file: File) {
      commit('LOAD')
      try {
        const x64File = await new Promise<string | ArrayBuffer | null>(
          (resolve, reject) => {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = (): void => {
              resolve(reader.result)
            }
            reader.onerror = (error): void => {
              console.error(error)
              reject(
                error instanceof Error
                  ? error
                  : new Error('Error reading file'),
              )
            }
          },
        )
        const results = await GuestsService.uploadFile(x64File)
        commit('SET_IMPORT_BATCH_ID', results.data)
        await dispatch('updateImportList', results.data)
      } catch (e) {
        ErrorService.handleError(e)
        commit('UNLOAD')
      }
    },

    async updateImportList({ commit, dispatch }, batchId) {
      const results = await GuestsService.getImportResult(batchId)
      commit('SET_IMPORT_FEEDBACK', results.data)
      if (results.data.toProcess && results.data.toProcess.length > 0) {
        setTimeout(async () => {
          await dispatch('updateImportList', batchId)
        }, 7000)
      } else {
        commit('UNLOAD')
      }
    },

    resetImport({ commit }) {
      commit('SET_IMPORT_FEEDBACK', {
        successes: [],
        errors: [],
        toProcess: [],
      })
      commit('SET_IMPORT_BATCH_ID', '')
    },

    async setDeletionMode({ commit, state }, guestId: string) {
      commit('LOAD')
      try {
        const eventList = await GuestsService.getGuestEvents([guestId])
        commit('SET_GUEST_EVENTS', eventList)
        const markerList = await GuestsService.getGuestMarkers([guestId])
        commit('SET_GUEST_MARKERS', markerList)
        state.mode = PAGE_MODE.DELETE
        state.deleteGuestId = guestId
      } catch (e) {
        ErrorService.handleError(e)
      } finally {
        commit('UNLOAD')
      }
    },

    async setToggleVisibilityMode(
      { commit, state },
      {
        guestId,
        show,
        names,
      }: { guestId: string; show: boolean; names: TranslatableText },
    ) {
      commit('LOAD')
      try {
        const eventList = await GuestsService.getGuestEvents([guestId])
        commit('SET_GUEST_EVENTS', eventList)
        state.mode = PAGE_MODE.TOGGLING_VISIBILITY
        state.togglingGuest = { guestId, names }
        state.showHideMode = show ? 'SHOW' : 'HIDE'
      } catch (e) {
        ErrorService.handleError(e)
      } finally {
        commit('UNLOAD')
      }
    },

    async setMultipleDeletionMode({ state, commit }) {
      commit('LOAD')
      try {
        const guestIds = state.selectedGuestRows
          .map((g) => g._id)
          .filter(isNotNullish)
        const eventList = await GuestsService.getGuestEvents(guestIds)
        commit('SET_GUEST_EVENTS', eventList)
        const markerList = await GuestsService.getGuestMarkers(guestIds)
        commit('SET_GUEST_MARKERS', markerList)
        commit('SET_DELETING_MULTIPLE_GUESTS')
      } catch (e) {
        ErrorService.handleError(e)
      } finally {
        commit('UNLOAD')
      }
    },

    async setMultipleVisibilityMode({ state, commit }, show: boolean) {
      commit('LOAD')
      try {
        const guestIds = state.selectedGuestRows
          .map((g) => g._id)
          .filter(isNotNullish)
        const eventList = await GuestsService.getGuestEvents(guestIds)
        commit('SET_GUEST_EVENTS', eventList)
        state.mode = PAGE_MODE.SETTING_VISIBILITY_MULTIPLE
        state.showHideMode = show ? 'SHOW' : 'HIDE'
      } catch (e) {
        ErrorService.handleError(e)
      } finally {
        commit('UNLOAD')
      }
    },

    async editGuestsBatch(
      { commit, dispatch, state },
      typeId: string | undefined,
    ) {
      commit('LOAD')
      try {
        const guestIds = state.selectedGuestRows
          .map((g) => g._id)
          .filter(isNotNullish)
        await GuestsService.editGuestsBatch(guestIds, typeId)
        await dispatch('getPaginatedGuests')
        SnackbarService.info(
          i18n.tc('GUESTS.SNACK_BAR.BATCH_EDIT', undefined, {
            string: guestIds.length,
          }),
        )
      } catch (e) {
        ErrorService.handleError(e)
      } finally {
        commit('RESET_MULTI_SELECTION')
        commit('UNLOAD')
      }
    },
  },
  getters: {
    filteredSearch: (state) => {
      const tab = Object.keys(state.guests).map((id) => state.guests[id])

      return state.search && state.search.length > 0
        ? tab.filter((n) =>
            n.names.fr.toLowerCase().includes(state.search.toLowerCase()),
          )
        : tab
    },

    getGuestsLight: (state) => {
      const guests = [...state.guestsIdsNames]
      guests.sort((a, b) =>
        (a.names?.fr.toLowerCase() ?? '') > (b.names?.fr.toLowerCase() ?? '')
          ? 1
          : -1,
      )
      return guests
    },

    getGuestsByType: (state) => {
      return (type: string): MusicGroup[] =>
        Object.values(state.guests).filter((g) => g.type === type)
    },

    getGuest: (state): MusicGroup => {
      return state.mode !== PAGE_MODE.EDIT
        ? {
            freeze: false,
            descriptions: {
              fr: '',
              en: '',
            },
            genres: [],
            location: [],
            links: {},
            names: { fr: '', en: '' },
            showArtist: false,
            type: '',
            villageEvent: false,
          }
        : state.editingGuest!
    },

    getLinks: (state) => {
      return state.links
    },

    getMultiDeletionCount: (state) => {
      return state.selectedGuestRows.length
    },

    showDeleteDialog: (state) => {
      return state.mode === PAGE_MODE.DELETE && state.deleteGuestId
    },

    showMultiDeleteDialog: (state) => {
      return (
        state.mode === PAGE_MODE.DELETE_MULTIPLE &&
        state.selectedGuestRows &&
        state.selectedGuestRows.length > 0
      )
    },

    showMultiEditDialog: (state) => {
      return (
        state.mode === PAGE_MODE.EDIT_MULTIPLE &&
        state.selectedGuestRows &&
        state.selectedGuestRows.length > 0
      )
    },

    showToggleVisibilityDialog: (state) => {
      return (
        state.mode === PAGE_MODE.TOGGLING_VISIBILITY &&
        state.togglingGuest != null
      )
    },

    showMultiSetVisibilityDialog: (state) => {
      return (
        state.mode === PAGE_MODE.SETTING_VISIBILITY_MULTIPLE &&
        state.selectedGuestRows &&
        state.selectedGuestRows.length > 0
      )
    },

    getSelectedGuestIds: (state) => state.selectedGuestRows.map((g) => g._id),

    // GuestEvents

    getCurrentGuestEvent: (state) => state.currentGuestEvent,

    showGuestEventDialog: (state) => state.currentGuestEvent != null,

    getGuestEvents: (state) => state.guestEvents,
    getGuestMarkers: (state) => state.deletingGuestMarkers,
  },
})
